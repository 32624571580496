@import "./colors";

.portfolio {
  height: 100%;
  text-align: center;
}

.portfolio .container {
  background: #fff;
  height: 100%;
  position: relative;
  width: 100%;
}

.portfolio .container .background {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
}

.portfolio .container .background > img {
  height: 100%;
  position: absolute;
  width: 100%;
}

.portfolio .container .profile {
  color: $off-yellow;
  display: flex;
  justify-content: center;
  z-index: 2;
}

.portfolio .container .profile .card {
  background: rgba(255, 152, 3, 0.6);
  border: 2px solid $off-yellow;
  border-radius: 10px;
  padding: 48px 24px;

  h1 { margin: 24px 0 0; }
}

.portfolio .container .profile img {
  border: 2px solid $off-yellow;
  border-radius: 100px;
  height: auto;
  max-width: 60%;
}

.portfolio .sections > div:nth-child(even) {
  background-color: $off-yellow;
}

.portfolio .section-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.portfolio .section {
  margin-bottom: 72px;
  max-width: 60%;
  min-height: 250px;
  width: 60%;
}